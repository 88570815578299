.container {
  display: flex;
  padding: 50px 0;
  justify-content: center;
  height: 100%;
}
.mainContainer {
  background-color: white;
  width: fit-content;
  height: fit-content;
  padding: 20px 30px 20px 30px;
  border-radius: 10px;
  border: 3px solid black;
}

.bodyContainer {
  width: 825px;
  margin: auto;
}
.cellContainer {
  width: 100%;
  justify-content: space-around;
  display: flex;
}
.greenButton {
  color: #2f9546;
  border-color: #2f9546;
  background-color: rgba(47, 149, 70, 0.1);
  &:hover {
    background-color: lighten(rgba(47, 149, 70, 0.1), 50%);
  }
}
.blackButton {
  color: black;
  border-color: black;
  background-color: rgba(0, 0, 0, 0.1);
  &:hover {
    background-color: lighten(rgba(0, 0, 0, 0.1), 50%);
  }
}
.searchBar {
  width: 500px;
  background-color: transparent;
}
.filtersContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.columnHeader {
  font-family: 'Roboto';
}
.centeredCell {
  text-align: center;
}
