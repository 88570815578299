.button {
  position: relative;
  width: fit-content;
  height: 30px;
  outline-width: 1px;
  user-select: none;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  font-family: 'Open Sans';
}
.button:focus {
  outline: none;
  background-color: rgb(219, 234, 254);
}
.innerText {
  margin: auto;
  font-weight: bold;
}
