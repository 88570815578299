.pillContainer {
    height: 30px;
    background-color: white;
    display: flex;
    border: 1px solid #cccccc;
    font-size: 12px;
    font-family: 'Open Sans';
    cursor: pointer;
    .activePill {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.1s all ease-out;
        padding: 0 6px;
        border-left: 1px solid #cccccc;
        white-space: nowrap;
        position: relative;
        background-color: black;
        color: white;
    }
    .inactivePill {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.1s all ease-out;
        padding: 0 6px;
        border-left: 1px solid #cccccc;
        white-space: nowrap;
        position: relative;
        color: black;
        &:hover {
            background-color: lightgray;
        }
    }
}