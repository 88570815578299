.container {
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../../assets/login_background.png");
}

.loginButton {
    background: #2F9546;
    color: white;
    position: relative;
    width: 60px;
    height: 49px;
    outline-width: 1px;
    user-select: none;
    border-style: solid;
    border-width: 1px;
    border-color: black;
    border-left: none;
    font-family: 'Roboto';
    cursor: pointer;
    &:hover {
        background: lighten(#2F9546, 5%);
    }
}
.loginBox {
    background-color: white;
    width: 30%;
    height: 30%;
    border: 1px solid black;
    padding: 20px;
    border-radius: 0.5rem
}
.emailInputContainer {
    width: 70%;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}
.logoContainer {
    height: 40%;
    text-align: center;
}
.authLogoContainer {
    height: 40%;
    text-align: center;
    margin-top: 8%;
}
.logo {
    height: 100%;
}
.loginLabel {
    font-weight: bolder;
    font-family: 'Roboto';
    font-size: 28px;
    margin-top: 20px;
    text-align: center;
}

.loginFlexBox {
    display: flex;
    flex-direction: column;
}

.invalidDomainLabel {
    align-self: center;
    color: red;
}