.container {
    display: flex;
    flex: column-reverse;
    font-size: 0.875rem;
    line-height: 1.25rem;
    background-color: white;
}

.innerContainer {
    display: flex;
    align-items: center;
    border-color: #dae1e7;
    border-width: 1px;
    outline: 2px solid transparent;
    outline-offset: 2px;
    padding: 0.75rem;
    height: 100%;
    width: 100%;
}