.inputContainer {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.25rem;
  position: relative;
  margin-bottom: 1rem;
}
.noLabelClass {
  position: absolute;
  display: block;
  color: #b8c2cc;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
.inputClass {
  background-color: white;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
  padding: 1rem;
  outline: 2px solid transparent;
  outline-offset: 2px;
  border-radius: 0.5rem;
  color: #787a7c;
  &:focus {
    border-color: #2f9546;
  }
}
.labelContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 0.25rem;
}
.labelClass {
  margin-bottom: 0.5rem;
  font-size: 16px;
  line-height: 1.5rem;
  user-select: none;
  font-weight: 400;
  font-family: "Open Sans";
}
.errorText {
  color: #ef5753;
  font-size: 0.75rem;
  line-height: 1rem;
}
